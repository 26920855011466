export const aiBookCallTranslations = {
  bookCall: {
    start: {
      en: "Quick Form, Big Impact: Schedule Your Free AI Discovery Call",
      es: "Formulario Rápido, Gran Impacto: Agenda Tu Llamada Gratuita de Descubrimiento de IA",
      de: "Kurzes Formular, Große Wirkung: Vereinbaren Sie Ihr Kostenloses KI-Beratungsgespräch",
    },
    bookCallStartButton: {
      en: "Begin",
      es: "Comenzar",
      de: "Beginnen",
    },
    position: {
      en: "What is your position in the company?",
      es: "¿Cuál es tu puesto en la empresa?",
      de: "Was ist Ihre Position im Unternehmen?",
    },
    companyInfo: {
      en: "How can we learn more about your company?",
      es: "¿Cómo podemos conocer más sobre tu empresa?",
      de: "Wie können wir mehr über Ihr Unternehmen erfahren?",
    },
    sectors: {
      en: "What sector or industry is your business in?",
      es: "¿En qué sector o industria opera tu empresa?",
      de: "In welchem Sektor oder in welcher Branche ist Ihr Unternehmen tätig?",
    },
    teamExperience: {
      en: "How experienced is your team with AI?",
      es: "¿Qué experiencia tiene tu equipo con IA?",
      de: "Wie erfahren ist Ihr Team im Umgang mit KI?",
    },
    aiConcernsTitle: {
      en: "Do you have any concerns about AI?",
      es: "¿Tienes alguna preocupación sobre la IA?",
      de: "Haben Sie Bedenken bezüglich der KI?",
    },
    companyName: {
      en: "Company name",
      es: "Nombre de la empresa",
      de: "Firmenname",
    },
    websiteOrLinkedIn: {
      en: "Website or LinkedIn",
      es: "Sitio web o LinkedIn",
      de: "Webseite oder LinkedIn",
    },
    reachOut: {
      en: "How can we reach out?",
      es: "¿Cómo podemos ponernos en contacto?",
      de: "Wie können wir Sie erreichen?",
    },
    contactName: {
      en: "Name",
      es: "Nombre",
      de: "Name",
    },
    contactEmail: {
      en: "Email",
      es: "Email",
      de: "Email",
    },
    next: {
      en: "Next",
      es: "Siguiente",
      de: "Weiter",
    },
    nextTodo: {
      en: "Next TODO",
      es: "Siguiente TODO",
      de: "Nächstes TODO",
    },
    companyAIExperience: {
      noExperience: {
        en: "No experience",
        es: "Sin experiencia",
        de: "Keine Erfahrung",
      },
      someExperience: {
        en: "Some experience",
        es: "Algo de experiencia",
        de: "Etwas Erfahrung",
      },
      intermediate: {
        en: "Intermediate",
        es: "Intermedio",
        de: "Mittelstufe",
      },
      advanced: {
        en: "Advanced",
        es: "Avanzado",
        de: "Fortgeschritten",
      },
    },
    aiConcerns: {
      costOfImplementation: {
        en: "Cost of implementation",
        es: "Costo de implementación",
        de: "Implementierungskosten",
      },
      dataPrivacySecurity: {
        en: "Data privacy and security",
        es: "Privacidad y seguridad de los datos",
        de: "Datenschutz und -sicherheit",
      },
      lackOfExpertise: {
        en: "Lack of in-house expertise",
        es: "Falta de experiencia interna",
        de: "Mangel an interner Fachkenntnis",
      },
      unclearROI: {
        en: "Unclear ROI or measurable benefits",
        es: "ROI o beneficios medibles poco claros",
        de: "Unklarer ROI oder messbare Vorteile",
      },
      noConcerns: {
        en: "No concerns",
        es: "Sin preocupaciones",
        de: "Keine Bedenken",
      },
    },
  },
};
