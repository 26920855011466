import React, { ReactNode, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useTranslate } from "../../hooks/useTranslation";
import { getSvg } from "../../Utils/svgs/getSvg";
import { ImageButton } from "../../Components/TabImageButton/ImageButton";
import { Slider } from "../../Components/Slider/Slider";
import { getThemeColor } from "../../tailwindUtils";

export type TabsSectionSectionType = {
  headline: string;
  text?: string;
  image?: string;
  content: ReactNode;
};

export type TabsSectionPropsType = {
  sections: TabsSectionSectionType[];
  right?: boolean;
  className?: string;
};

export const TabsSection = ({
  sections,
  right,
  className,
}: TabsSectionPropsType) => {
  const translate = useTranslate();
  const [selectedService, setSelectedService] = useState<number>(0);

  const renderContent = (index: number, isMobile: boolean = false) => (
    <AnimatePresence mode="wait">
      <motion.div
        key={index}
        className="relative w-full h-full z-10 flex gap-20 flex-col items-center justify-center"
      >
        {sections[index].image && (
          <motion.div
            key={sections[index].image}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 0.3, y: 0 }}
            transition={{ duration: 2 }}
            className="w-full h-full absolute"
          >
            {sections[index].image &&
              getSvg(
                sections[index].image || "",
                getThemeColor("accent"),
                "100%",
                "100%"
              )}
          </motion.div>
        )}
        {sections[index].text && (
          <motion.p
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.1 }}
            viewport={{ once: true }}
            className="body-1 max-w-[1000px] text-grey font-medium text-center"
          >
            {translate(sections[index].text!)}
          </motion.p>
        )}
        <motion.ul
          className={`w-full relative gap-4 flex flex-col items-center ${
            isMobile ? "pb-5" : ""
          }`}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          {sections[index].content}
        </motion.ul>
      </motion.div>
    </AnimatePresence>
  );

  const mobileSlides = sections.map((service, index) => (
    <div
      key={index}
      className="flex flex-col items-center justify-between gap-y-4 h-full"
    >
      <div className="mt-auto text-center">
        <h2 className="heading-3 font-bold text-accent">
          {translate(service.headline)}
        </h2>
      </div>
      <div className="flex-grow w-full">{renderContent(index, true)}</div>
    </div>
  ));

  return (
    <section
      className={`${
        className ? className : ""
      } rounded-lg h-fit w-full relative lg:mx-auto `}
    >
      <div className={`flex full-w p-10 pt-0 flex-col  ${right ? "" : ""} `}>
        <div className="lg:block hidden">
          <div className="mx-auto flex gap-5 border border-accent/20 bg-white shadow-sm rounded-lg w-fit p-1 justify-center items-center flex-row">
            {sections.map((service, index) => (
              <button
                onClick={() => setSelectedService(index)}
                className={`text-center rounded-lg w-fit font-medium px-4 py-2 transition-colors duration-300 ${
                  selectedService === index
                    ? "text-accent bg-accent/20  hover:cursor-default"
                    : "text-main hover:text-accent"
                }`}
              >
                <p className={`body-1 w-fit ${right ? "lg:ml-auto" : ""}`}>
                  {translate(service.headline)}
                </p>
              </button>
            ))}
          </div>
        </div>
        <div className="lg:block hidden p-6 pt-10 flex items-center relative justify-center min-h-full ">
          {renderContent(selectedService)}
        </div>
      </div>
      <div className="lg:hidden">
        <Slider
          slides={mobileSlides}
          className="w-full h-full"
          slideClassName="flex flex-col  px-5 md:px-20"
        />
      </div>
    </section>
  );
};
