import {
  CardsShowcaseType,
  ShowcaseCard,
} from "../../Components/ShowcaseCard/ShowcaseCard";

type CardsShowcasePropsType = {
  cards: CardsShowcaseType[];
};

export const CardsShowcase = ({ cards }: CardsShowcasePropsType) => {
  return (
    <div className="flex flex-col md:grid grid-cols-1 md:grid-cols-2 md:grid-rows-2 mx-auto gap-10">
      {cards.map((card, index) => {
        return (
          <ShowcaseCard
            key={card.title}
            className={`${
              index === 0
                ? "relative col-start-1 row-span-2 h-[380px] md:h-auto"
                : "col-start-2 h-[380px] "
            } `}
            card={card}
          />
        );
      })}
    </div>
  );
};
