import { useNavigate } from "react-router";
import { ArticleType } from "../../Articles/getArticles";
import { ArticleCard } from "../ArticleCard/ArticleCard";
import { Button } from "../Button/Button";
import { FadeSeparator } from "../FadeSeparator/FadeSeparator";
import { ArrowLeft } from "lucide-react";
import { escape } from "querystring";
import { translationLanguageAtom } from "../../jotai/state";
import { useAtom } from "jotai";

type ArticleSidebarPropsType = {
  articles: ArticleType[];
};

export const backArticlesLabel: any = {
  en: "All Articles",
  de: "Alle Artikel",
  es: "Todos los artículos",
};

export const relatedArticles: any = {
  en: "All Articles",
  de: "Verwandte Artikel",
  es: "Artículos relacionados",
};

export const ArticleSidebar = ({ articles }: ArticleSidebarPropsType) => {
  const navigate = useNavigate();
  const [lang] = useAtom(translationLanguageAtom);

  return (
    <div className="bg-white border-smooth  max-w-[400px] p-5 rounded-lg">
      <h1 className="body-2 font-medium mb-5 text-lightGrey">
        {relatedArticles[lang]}
      </h1>
      <div>
        {articles.map((article) => {
          return <ArticleCard showDescription={false} article={article} />;
        })}
      </div>
      <div className="flex flex-row gap-5 items-center justify-between w-full relative">
        <FadeSeparator className="top-0" />
        <Button
          label={backArticlesLabel[lang]}
          className="mt-5 w-full"
          iconLeft
          icon={<ArrowLeft />}
          action={() => navigate("/articles")}
        />
      </div>
    </div>
  );
};
