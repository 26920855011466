export const article_5 = {
  title: {
    en: "AI-Driven Talent Assessment: Customized Applicant Profiling Form",
    es: "Evaluación de Talento Impulsada por IA: Formulario de Perfil Personalizado para Solicitantes",
    de: "KI-gestützte Talentbewertung: Anpassbare Bewerberprofilierungsform",
  },
  metadata: {
    id: 5,
    postingDate: "12.6.2024",
    image: require("./images/5.png"),
    tag: { en: "AI Case Study", de: "AI-Fallstudie", es: "Caso de estudio IA" },
    description: {
      en: "How AI customization helped a tech startup make better hiring decisions, reducing turnover and speeding up the hiring process.",
      es: "Cómo la personalización de la IA ayudó a un startup tecnológico a tomar mejores decisiones de contratación, reduciendo el turnover y acelerando el proceso de selección.",
      de: "Wie die Anpassung von KI einem Tech-Startup half, bessere Einstellungsentscheidungen zu treffen, den Mitarbeiterfluktuation zu reduzieren und den Einstellungsprozess zu beschleunigen.",
    },
    author: "Millerio",
  },
  sections: [
    {
      heading: {
        en: "Executive Summary",
        es: "Resumen Ejecutivo",
        de: "Zusammenfassung",
      },
      content: [
        {
          en: "A tech startup struggled to efficiently assess soft skills and cultural fit of applicants.",
          es: "Una startup tecnológica tuvo dificultades para evaluar eficientemente las habilidades blandas y la adaptación cultural de los solicitantes.",
          de: "Ein Technologie-Startup hatte Schwierigkeiten, die Soft Skills und die kulturelle Passung von Bewerbern effizient zu bewerten.",
        },
        {
          en: "We created an AI-driven customized applicant profiling form.",
          es: "Creamos un formulario de perfil personalizado para solicitantes impulsado por IA.",
          de: "Wir entwickelten ein maßgeschneidertes Bewerberprofilierungsformular, das von KI gesteuert wird.",
        },
        {
          en: "Results: 50% improvement in predicting candidate success, 33% reduction in early-stage turnover, 30% faster time-to-hire.",
          es: "Resultados: Mejora del 50% en la predicción del éxito del candidato, reducción del 33% en la rotación inicial, 30% más rápido en el tiempo de contratación.",
          de: "Ergebnisse: 50% Verbesserung bei der Vorhersage des Kandidatenerfolgs, 33% Reduktion des frühen Mitarbeiterwechsels, 30% schnellerer Einstellungsprozess.",
        },
      ],
    },
    {
      heading: {
        en: "Our AI Solution",
        es: "Nuestra Solución de IA",
        de: "Unsere KI-Lösung",
      },
      content: [
        {
          en: "Adaptive questioning based on applicant responses and job requirements.",
          es: "Preguntas adaptativas basadas en las respuestas del solicitante y los requisitos del empleo.",
          de: "Anpassbare Fragen basierend auf den Antworten des Bewerbers und den Anforderungen der Stelle.",
        },
        {
          en: "Natural Language Processing to analyze free-text responses.",
          es: "Procesamiento del Lenguaje Natural para analizar respuestas en texto libre.",
          de: "Verarbeitung natürlicher Sprache zur Analyse von freien Textantworten.",
        },
        {
          en: "Machine Learning models to predict candidate fit and potential performance.",
          es: "Modelos de Aprendizaje Automático para predecir la compatibilidad y el desempeño potencial del candidato.",
          de: "Maschinelles Lernen-Modelle zur Vorhersage der Passgenauigkeit und des möglichen Leistungsvermögens des Kandidaten.",
        },
      ],
    },
    {
      heading: {
        en: "Results and Impact",
        es: "Resultados e Impacto",
        de: "Ergebnisse und Auswirkungen",
      },
      content: {
        quantitative: [
          {
            en: "50% better prediction of success",
            es: "50% mejor predicción de éxito",
            de: "50% bessere Erfolgsvorhersage",
          },
          {
            en: "33% lower early-stage turnover",
            es: "33% menor rotación en la etapa inicial",
            de: "33% geringerer Frühverlust",
          },
          {
            en: "30% faster hiring process",
            es: "30% más rápido en el proceso de contratación",
            de: "30% schnellerer Einstellungsprozess",
          },
        ],
        qualitative: [
          {
            en: "Improved candidate experience",
            es: "Mejorada experiencia del candidato",
            de: "Verbesserte Bewerber-Erfahrung",
          },
          {
            en: "Better alignment with company culture",
            es: "Mejor alineación con la cultura de la empresa",
            de: "Bessere Anpassung an die Unternehmenskultur",
          },
          {
            en: "More informed hiring decisions",
            es: "Decisiones de contratación más informadas",
            de: "Informiertere Einstellungsentscheidungen",
          },
        ],
      },
    },
    {
      heading: {
        en: "Future Opportunities",
        es: "Oportunidades Futuras",
        de: "Zukünftige Möglichkeiten",
      },
      content: [
        {
          en: "Develop AI-powered video interview analysis to complement the profiling form.",
          es: "Desarrollar análisis de entrevistas en video impulsado por IA para complementar el formulario de perfil.",
          de: "Entwicklung einer KI-gestützten Video-Interview-Analyse, um das Profilierungsformular zu ergänzen.",
        },
        {
          en: "Create an AI coach to provide feedback to candidates on their responses and suggest areas for improvement.",
          es: "Crear un coach de IA para proporcionar retroalimentación a los candidatos sobre sus respuestas y sugerir áreas de mejora.",
          de: "Erstellen eines KI-Coachs, um Kandidaten Feedback zu ihren Antworten zu geben und Verbesserungsmöglichkeiten vorzuschlagen.",
        },
      ],
    },
  ],
};
