import { useAtom } from "jotai";
import { StepFormStepType } from "../StepFormTypes";
import {
  FormUserActionType,
  useRenderStepAnswer,
} from "../useRenderStepAnswer";
import { formStateAtom } from "../FormState/FormState";
import { motion } from "framer-motion";

type StepFormAnswerPropsType = {
  currentStepData: StepFormStepType;
  translate: string;
  handleUserAction: (action: FormUserActionType) => void;
};

export const StepFormAnswer = ({
  currentStepData,
  translate,
  handleUserAction,
}: StepFormAnswerPropsType) => {
  const [formState, setFormState] = useAtom(formStateAtom);

  const answerInState: any = formState?.userActions[currentStepData.id];

  return (
    <div
      key={currentStepData.id}
      className={`w-full gap-5 flex items-center justify-center `}
    >
      {useRenderStepAnswer(
        currentStepData.answer,
        answerInState,
        (action) => handleUserAction(action),
        translate
      )}
    </div>
  );
};
