import { atom } from "jotai";

export type FormStateAnswerInputFieldType = {
  [key: string]: { fieldLabel: string; inputValue: string };
};

export type FormStateUserAnswerType = FormStateAnswerInputFieldType | string;

type FormStateAnswerType = {
  step_title: string;
  user_answer: FormStateUserAnswerType;
};

export interface FormStateType {
  formId: string;
  userActions: { [key: string]: FormStateAnswerType };
}

export const formStateAtom = atom<FormStateType | null>();
export const currentFormStepAtom = atom<string | null>();
