export const article_6 = {
  title: {
    en: "AI Sales Representative for B2B Industrial Outreach",
    es: "Representante de Ventas de IA para Alcance Industrial B2B",
    de: "KI-Verkaufsvertreter für B2B-Industrievermarktung",
  },
  metadata: {
    id: 6,
    postingDate: "2.3.2024",
    image: require("./images/6.png"),
    tag: { en: "AI Case Study", de: "AI-Fallstudie", es: "Caso de estudio IA" },
    description: {
      en: "An AI sales strategy that doubled outreach and refined lead quality for an industrial equipment supplier.",
      es: "Una estrategia de ventas basada en IA que duplicó el alcance y refinó la calidad de los leads para un proveedor de equipos industriales.",
      de: "Eine KI-basierte Verkaufsstrategie, die den Reach verdoppelte und die Lead-Qualität für einen Anbieter industrieller Ausrüstungen verbesserte."
    }, author: "Millerio",
  },
  sections: [
    {
      heading: {
        en: "Executive Summary",
        es: "Resumen Ejecutivo",
        de: "Zusammenfassung",
      },
      content: [
        {
          en: "An industrial equipment supplier wanted to scale their outreach to potential B2B clients.",
          es: "Un proveedor de equipo industrial quería aumentar su alcance hacia clientes potenciales B2B.",
          de: "Ein Lieferant für Industriegeräte wollte seine Ansprache an potenzielle B2B-Kunden skalieren.",
        },
        {
          en: "We implemented an AI sales representative system for initial outreach and qualification.",
          es: "Implementamos un sistema de representante de ventas de IA para el alcance inicial y la calificación.",
          de: "Wir implementierten ein KI-Verkaufsvertretersystem für die erste Ansprache und Qualifikation.",
        },
        {
          en: "Results: 100% increase in outreach capacity, 40% more qualified leads, 20% higher conversion rate to sales calls.",
          es: "Resultados: Aumento del 100% en la capacidad de alcance, 40% más de leads calificados, tasa de conversión al 20% más alta para llamadas de ventas.",
          de: "Ergebnisse: 100% Zunahme der Anspruchsreichweite, 40% mehr qualifizierte Leads, 20% höhere Konversionsrate zu Verkaufsgesprächen.",
        },
      ],
    },
    {
      heading: {
        en: "Our AI Solution",
        es: "Nuestra Solución de IA",
        de: "Unsere KI-Lösung",
      },
      content: [
        {
          en: "Natural Language Processing for understanding and generating human-like communications.",
          es: "Procesamiento del Lenguaje Natural para comprender y generar comunicaciones similares a las humanas.",
          de: "Verarbeitung natürlicher Sprache zur Verständigung und Erzeugung menschlicher Kommunikation.",
        },
        {
          en: "Machine Learning for lead scoring and personalized outreach strategies.",
          es: "Aprendizaje Automático para la puntuación de leads y estrategias de alcance personalizado.",
          de: "Maschinelles Lernen für Lead-Bewertung und personalisierte Ansprachestrategien.",
        },
        {
          en: "Integration with CRM systems for seamless data flow and follow-ups.",
          es: "Integración con sistemas CRM para un flujo de datos sin problemas y seguimientos.",
          de: "Integration in CRM-Systeme für reibungslose Datenflüsse und Nachverfolgungen.",
        },
      ],
    },
    {
      heading: {
        en: "Results and Impact",
        es: "Resultados e Impacto",
        de: "Ergebnisse und Auswirkungen",
      },
      content: {
        quantitative: [
          {
            en: "100% more contacts reached",
            es: "100% más de contactos alcanzados",
            de: "100% mehr Kontakte erreicht",
          },
          {
            en: "40% increase in qualified leads",
            es: "40% aumento en leads calificados",
            de: "40% Anstieg an qualifizierten Leads",
          },
          {
            en: "20% higher conversion to calls",
            es: "20% mayor conversión a llamadas",
            de: "20% höhere Konversion zu Gesprächen",
          },
        ],
        qualitative: [
          {
            en: "Consistent brand messaging",
            es: "Mensajería de marca consistente",
            de: "Konsistente Markenkommunikation",
          },
          {
            en: "Improved efficiency of human sales team",
            es: "Mejora en la eficiencia del equipo de ventas humano",
            de: "Verbesserte Effizienz des menschlichen Verkaufsteams",
          },
          {
            en: "Enhanced lead nurturing process",
            es: "Proceso de cuidado de leads mejorado",
            de: "Verbessertes Lead-Pflegeverfahren",
          },
        ],
      },
    },
    {
      heading: {
        en: "Future Opportunities",
        es: "Oportunidades Futuras",
        de: "Zukünftige Möglichkeiten",
      },
      content: [
        {
          en: "Expand AI capabilities to handle entire sales cycles for certain product categories.",
          es: "Expandir las capacidades de IA para manejar ciclos completos de ventas para ciertas categorías de productos.",
          de: "Erweiterung der KI-Fähigkeiten, um komplette Verkaufszyklen für bestimmte Produktkategorien zu bewältigen.",
        },
        {
          en: "Develop an AI-powered pricing optimization system based on customer interactions and market data.",
          es: "Desarrollar un sistema de optimización de precios impulsado por IA basado en interacciones de clientes y datos del mercado.",
          de: "Entwicklung eines KI-gestützten Preisooptimierungssystems basierend auf Kundeninteraktionen und Marktdaten.",
        },
      ],
    },
  ],
};
