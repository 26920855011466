import { useEffect, useState } from "react";

import { ParticlesBackground } from "./Components/ParticlesBackground/ParticlesBackground";

import { Home } from "./pages/Home/Home";
import { Header } from "./modules/Header/Header";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Footer } from "./modules/Footer/Footer";
import { AIPage } from "./pages/AI/AIPage";
import { DevelopmentPage } from "./pages/Development/DevelopmentPage";
import { FormsPage } from "./pages/FormsPage/FormsPage";
import { ThemeProvider, useTheme } from "./Providers/ThemeProvider";
import { ScrollProvider } from "./Providers/ScrollContextProvider";
import { ArticlesPage } from "./pages/Articles/ArticlesPage";
import { ArticlePage } from "./pages/Article/ArticlePage";
import { FormPage } from "./pages/FormPage/FormPage";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <ThemeProvider>
      <ScrollProvider>
        <div>
          <ParticlesBackground />
          <div className="relative">
            <Header />
            <Routes>
              <Route path="/" element={<AIPage />} />
              <Route path="/articles" element={<ArticlesPage />} />
              <Route path="/article/:id" element={<ArticlePage />} />
              <Route path="/form/:id" element={<FormPage />} />
              {/* <Route path="/" element={<Home />} />
            <Route path="/development" element={<DevelopmentPage />} />
            <Route path="/forms" element={<FormsPage />} /> */}
            </Routes>
            <Footer />
          </div>
        </div>
      </ScrollProvider>
    </ThemeProvider>
  );
}

export default App;
