export const article_7 = {
  title: {
    en: "AI-Managed Social Media Scheduling via DMs for Fitness Instructors",
    es: "Programación de Medios Sociales Gestionada por IA a través de Mensajes Directos para Instructores de Fitness",
    de: "KI-gestützte Sozialen-Medien-Terminierung über Direktnachrichten für Fitnesslehrer",
  },
  metadata: {
    id: 7,
    postingDate: "2.12.2023",
    image: require("./images/7.png"),
    tag: { en: "AI Case Study", de: "AI-Fallstudie", es: "Caso de estudio IA" },
    description: {
      en: "The impact of AI on fitness class scheduling through social media, enhancing attendance and client retention.",
      es: "El impacto de la IA en la programación de clases de fitness a través de las redes sociales, mejorando la asistencia y la retención de clientes.",
      de: "Die Auswirkungen von KI auf die Terminierung von Fitnessklassen über soziale Medien, wodurch die Teilnahme und Kundenbindung verbessert wurden.",
    },
    author: "Millerio",
  },
  sections: [
    {
      heading: {
        en: "Executive Summary",
        es: "Resumen Ejecutivo",
        de: "Zusammenfassung",
      },
      content: [
        {
          en: "A fitness studio chain struggled with efficient class scheduling and client communication.",
          es: "Una cadena de estudios de fitness tuvo dificultades con la programación eficiente de clases y la comunicación con clientes.",
          de: "Eine Fitnessstudio-Kette hatte Schwierigkeiten mit der effizienten Kursplanung und der Kundenkommunikation.",
        },
        {
          en: "We developed an AI system to manage scheduling via social media direct messages.",
          es: "Desarrollamos un sistema de IA para gestionar la programación a través de mensajes directos en redes sociales.",
          de: "Wir entwickelten ein KI-System zur Verwaltung der Terminierung über soziale Medien-Direktnachrichten.",
        },
        {
          en: "Results: +50% reduction in scheduling conflicts, 30% increase in class attendance, 25% growth in client retention.",
          es: "Resultados: Reducción del 50% en conflictos de programación, aumento del 30% en la asistencia a clases, crecimiento del 25% en la retención de clientes.",
          de: "Ergebnisse: +50% Reduktion von Terminierungskonflikten, 30% Zunahme an Kursbesuchen, 25% Wachstum bei der Kundenbindung.",
        },
      ],
    },
    {
      heading: {
        en: "Our AI Solution",
        es: "Nuestra Solución de IA",
        de: "Unsere KI-Lösung",
      },
      content: [
        {
          en: "Natural Language Processing to understand and respond to client messages.",
          es: "Procesamiento del Lenguaje Natural para entender y responder a los mensajes de los clientes.",
          de: "Verarbeitung natürlicher Sprache zum Verstehen und Beantworten von Kundenbotschaften.",
        },
        {
          en: "Machine Learning algorithms for optimal class scheduling and instructor allocation.",
          es: "Algoritmos de Aprendizaje Automático para la programación óptima de clases y asignación de instructores.",
          de: "Maschinelles Lernen-Algorithmen für optimale Kursplanung und Lehraufgabenzuweisung.",
        },
        {
          en: "Automated reminders and follow-ups to reduce no-shows.",
          es: "Recordatorios automatizados y seguimientos para reducir las ausencias sin notificación.",
          de: "Automatisierte Erinnerungen und Nachverfolgungen, um Ausbleiber zu reduzieren.",
        },
      ],
    },
    {
      heading: {
        en: "Results and Impact",
        es: "Resultados e Impacto",
        de: "Ergebnisse und Auswirkungen",
      },
      content: {
        quantitative: [
          {
            en: "+50% fewer scheduling conflicts",
            es: "50% menos conflictos de programación",
            de: "+50% weniger Terminierungskonflikte",
          },
          {
            en: "30% higher class attendance",
            es: "30% mayor asistencia a clases",
            de: "30% höhere Kursbesuchszahlen",
          },
          {
            en: "25% improved client retention",
            es: "25% de mejora en la retención de clientes",
            de: "25% verbesserte Kundenbindung",
          },
        ],
        qualitative: [
          {
            en: "Enhanced client experience",
            es: "Experiencia del cliente mejorada",
            de: "Verbesserte Kundenerfahrung",
          },
          {
            en: "Reduced administrative workload for instructors",
            es: "Reducción de la carga administrativa para los instructores",
            de: "Reduzierte administrative Arbeitslast für Lehrer",
          },
          {
            en: "More efficient studio operations",
            es: "Operaciones del estudio más eficientes",
            de: "Effizientere Studio-Betriebsprozesse",
          },
        ],
      },
    },
    {
      heading: {
        en: "Future Opportunities",
        es: "Oportunidades Futuras",
        de: "Zukünftige Möglichkeiten",
      },
      content: [
        {
          en: "Integrate AI-driven personalized workout recommendations based on client history and goals.",
          es: "Integrar recomendaciones personalizadas de entrenamiento impulsadas por IA basadas en el historial y metas del cliente.",
          de: "Integration persönlicher Trainingsempfehlungen durch KI basierend auf Kundenhistorie und Zielen.",
        },
        {
          en: "Expand system to handle payments and membership management through social media platforms.",
          es: "Expandir el sistema para manejar pagos y gestión de membresías a través de plataformas de redes sociales.",
          de: "System erweitern, um Zahlungen und Mitgliedsmanagement über soziale Medien-Plattformen zu handhaben.",
        },
      ],
    },
  ],
};
