import React from "react";
import { PageWrapper } from "../PageWrapper";
import { ArticleType, getArticles } from "../../Articles/getArticles";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../Components/Button/Button";
import {
  ArticleSidebar,
  backArticlesLabel,
} from "../../Components/ArticleSidebar/ArticleSidebar";
import { FadeSeparator } from "../../Components/FadeSeparator/FadeSeparator";
import { ArrowLeft } from "lucide-react";
import { useAtom } from "jotai";
import { translationLanguageAtom } from "../../jotai/state";

export const ArticlePage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [lang] = useAtom(translationLanguageAtom);

  const articles: ArticleType[] = getArticles("ai");

  const article = articles.find(
    (article: ArticleType) => article.metadata.id.toString() === id
  );

  if (!article) {
    return (
      <div className="flex items-center justify-center h-screen text-2xl font-light">
        No article found with that ID
      </div>
    );
  }

  // Find the index of the current article in the articles array
  const currentArticleIndex = articles.findIndex(
    (article: ArticleType) => article.metadata.id.toString() === id
  );

  // Get the next three articles, ensuring you don't go out of bounds
  const relatedArticles = articles.slice(
    currentArticleIndex + 1,
    currentArticleIndex + 4
  );

  const shareLabel: any = {
    en: "Share",
    de: "Teilen",
    es: "Compartir",
  };

  return (
    <PageWrapper>
      <div className="my-20 lg:mt-20 mx-auto flex flex-col items-center">
        <Button
          label={backArticlesLabel[lang]}
          className="mb-20"
          iconLeft
          icon={<ArrowLeft />}
          action={() => navigate("/articles")}
        />
        <h1 className="heading-1 relative text-gradient  text-center">
          {article.title[lang]}
        </h1>
        <div className="rounded-full py-2 px-4 mt-5 border border-secondary text-secondary w-fit">
          {article.metadata.tag[lang]}
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row-reverse relative items-start justify-center gap-20 lg:gap-40 pb-2">
        <ArticleSidebar articles={relatedArticles} />
        <div className="flex flex-col items-center gap-5 max-w-[900px]">
          <div className="w-full overflow-hidden">
            <img
              src={article.metadata.image}
              alt={article.title["en"]}
              className="w-full h-[450px] object-cover  rounded-2xl"
            />
            <div className="flex flex-col pt-10">
              {article.sections.map((section, index) => (
                <div className="mb-10" key={index}>
                  <h2 className="heading-2 relative mb-2 text-accent">
                    {section.heading["en"]}
                    <span className="absolute inset-x-0 bottom-0 h-0.5"></span>
                  </h2>
                  {Array.isArray(section.content)
                    ? section.content.map((content, idx) => (
                        <p key={idx} className="body-1 mb-2">
                          {content["en"]}
                        </p>
                      ))
                    : Object.entries(section.content).map(
                        ([kind, results], idx) => (
                          <div key={idx} className="ml-2 mb-2">
                            <h3 className="body-1 capitalize mb-1 ">{kind}</h3>
                            <ul className="list-disc list-inside pl-6 body-2 leading-relaxed">
                              {results.map((result, resIdx) => (
                                <li key={resIdx}>{result["en"]}</li>
                              ))}
                            </ul>
                          </div>
                        )
                      )}
                </div>
              ))}
              <div className="flex items-center justify-between mt-5 relative pt-5">
                <FadeSeparator className="top-0" />
                <p className="body-2">{article.metadata.postingDate}</p>
                <div className="flex space-x-2 pb-2">
                  <Button
                    label={shareLabel[lang]}
                    action={function (): void {
                      //TODO
                      throw new Error("Function not implemented.");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-sm text-lightGrey mt-10">
            <p className="font-bold mb-2">Client Confidentiality Note</p>
            <p className="">
              While this case study is based on a real project, to promote fair
              competition and protect innovation in the rapidly evolving AI
              landscape, we've anonymized client details and specific
              technologies used. Client information and technology specifics may
              be disclosed upon request, subject to client approval. For more
              information or to discuss a similar solution for your business,
              please contact us.
            </p>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
