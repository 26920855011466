import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import { X } from "lucide-react";

import { LanguageSelector } from "../../Components/LanguageSelector/LanguageSelector";
import { FadeSeparator } from "../../Components/FadeSeparator/FadeSeparator";

import { getSvg } from "../../Utils/svgs/getSvg";
import { getThemeColor } from "../../tailwindUtils";
import { useTranslate } from "../../hooks/useTranslation";

export const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const translate = useTranslate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isMenuOpen]);

  const headerLinks = [ 
    { label: "ai.discoveryCallText", link: "/form/discovery" },
    { label: "ai.joinNetwork", link: "/form/talent" },
    { label: "articles", link: "/articles" },
  ];

  const Logo = (scroll: boolean) => (
    <Link
      to="/"
      onClick={() =>
        location.pathname === "/" && scroll ? window.scrollTo(0, 0) : undefined
      }
      className="flex items-center group"
    >
      {getSvg(
        "logo",
        getThemeColor("accent"),
        "30px",
        "30px",
        getThemeColor("secondary")
      )}
      <h1 className="pl-2 group-hover:text-accent transition-200 text-lg font-medium tracking-wider">
        MILLERIO
      </h1>
    </Link>
  );

  return (
    <header
      className={`fixed w-full z-50 transition-colors duration-200 ${
        isScrolled ? "bg-background" : "bg-transparent"
      }`}
    >
      <div className="container min-w-full px-4 py-4 flex justify-between items-center">
        {Logo(true)}
        <nav className="hidden lg:flex items-center space-x-6">
          {headerLinks.map((link, index) => (
            <React.Fragment key={index}>
              <Link
                to={link.link}
                className="body-2 hover:text-accent transition-colors"
              >
                {translate(link.label)}
              </Link>
            </React.Fragment>
          ))}
          <LanguageSelector />
        </nav>

        <button
          className="lg:hidden text-2xl h-10 w-10 flex items-center justify-center z-50 relative"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? <X /> : "☰"}
        </button>
      </div>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, x: "100%" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "100%" }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="fixed inset-0 bg-background-transparent backdrop-blur-2xl lg:hidden flex flex-col justify-center items-center z-40"
          >
            <div className="mb-10">{Logo(false)}</div>
            <nav className="flex flex-col space-y-6 text-center items-center">
              {headerLinks.map((link, index) => (
                <React.Fragment key={index}>
                  <Link
                    to={link.link}
                    className="heading-4 hover:text-accent transition-colors"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {link.label}
                  </Link>
                </React.Fragment>
              ))}
              <div className="pt-5 w-fit">
                <LanguageSelector />
              </div>
            </nav>
          </motion.div>
        )}
      </AnimatePresence>
      <FadeSeparator />
    </header>
  );
};
