import "./RotatingText.scss";

export const RotatingText = ({ strings }: { strings: string[] }) => {
  const heightClassName = `h-[40px] md:h-[70px] lg:h-[100px]`;
  const textSizeClassName = `heading-1 text-center text-nowrap mx-auto`;
  return (
    <div className="rotating-text w-full">
      <span className={`relative w-full`}>
        <span className={`invisible w-full ${textSizeClassName}`}>{strings[0]}</span>
        <div className="cube-container nowrap  text-center flex justify-center">
          {strings.map((string, i) => {
            return (
              <div
                key={string}
                className={`text-gradient nowrap !leading-none cube-face-${
                  i + 1
                } ${heightClassName} ${textSizeClassName}`}
              >
                {string}
              </div>
            );
          })}
        </div>
      </span>
    </div>
  );
};
