import { useAtom, useSetAtom } from "jotai";
import {
  currentFormStepAtom,
  FormStateAnswerInputFieldType,
  formStateAtom,
  FormStateUserAnswerType,
} from "../FormState/FormState";
import { FormUserActionType } from "../useRenderStepAnswer";
import { StepFormStepType } from "../StepFormTypes";
import { useStepFormTranslations } from "../StepFormTranslations/useStepFormTranslations";
import { translationLanguageAtom } from "../../jotai/state";
import { useFormspreeSubmission } from "../FormSubmission/useFormSpreeSubmission";

export const useUserAction = (
  formId: string,
  currentStepData: StepFormStepType
) => {
  const [formState, setFormState] = useAtom(formStateAtom);
  const setCurrentFormStep = useSetAtom(currentFormStepAtom);
  const [lang] = useAtom(translationLanguageAtom);
  const answerInState: any = formState?.userActions[currentStepData.id];

  const translateString = useStepFormTranslations(lang);

  const updateFormState = (
    stepHeadline: string = "No Title",
    userAnswer: FormStateUserAnswerType
  ) => {
    setFormState((prevState) => ({
      formId: formId,
      userActions: {
        ...prevState?.userActions,
        [currentStepData.id]: {
          step_title: translateString(stepHeadline, "en"),
          user_answer:
            typeof userAnswer === "string"
              ? translateString(userAnswer, "en")
              : userAnswer,
        },
      },
    }));
  };

  const { submitForm } = useFormspreeSubmission("xnnakegj");

  const handleUserAction = (action: FormUserActionType) => {
    const stepHeadline = currentStepData.title;

    switch (action.userActionType) {
      case "button_options":
        const userAnswer = action.actionData.label;

        switch (action.actionData.actionType) {
          case "changeStep":
            setCurrentFormStep(action.actionData.nextStepId);
            break;
        }

        updateFormState(stepHeadline, userAnswer);
        break;
      case "input_fields":
        const updatedField = action.actionData.field;
        const updatedFieldValue = action.actionData.value;

        const inputFieldsInStep = answerInState
          ? answerInState.user_answer
          : undefined;

        const newAnswerInState: FormStateAnswerInputFieldType = {
          ...inputFieldsInStep,
          [updatedField.id]: {
            fieldLabel: updatedField.label,
            inputValue: updatedFieldValue,
          },
        };
        updateFormState(stepHeadline, newAnswerInState);
        break;
      case "input_fields_button":
        if (action.submitForm) {
          console.log("Submitting form");
          if (formState) {
            submitForm(formState);
          } else {
            console.error("Error: FormState is null");
          }
        }
        setCurrentFormStep(action.nextStepId);
        break;
      default:
        break;
    }
  };

  console.log(formState)

  return { handleUserAction };
};
