import { Link } from "react-router-dom";

export type CardsShowcaseType = {
  title: string;
  image: string;
  text: string;
  link: string;
  tag?: string;
};

type ShowcaseCardPropsType = {
  card: CardsShowcaseType;
  className?: string;
};

export const ShowcaseCard = ({ card, className }: ShowcaseCardPropsType) => {
  return (
    <Link
      to={card.link}
      className={`group relative overflow-hidden rounded-2xl ${className}`}
    >
      <div className=" h-full relative">
        <img
          src={card.image}
          alt={card.title}
          className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-700"
        />
        {card.tag && (
          <div className="rounded-full absolute top-5 right-5 bg-white text-secondary border border-secondary  px-4 py-2">
            {card.tag}
          </div>
        )}
      </div>
      <div className="p-5 flex flex-col bottom-0 gap-5 items-between absolute bg-main/80 backdrop-blur-sm	">
        <h2 className="heading-3 text-gradient font-bold">{card.title}</h2>
        {card.text && (
          <div>
            <div className="body-1 text-white">{card.text}</div>
          </div>
        )}
      </div>
    </Link>
  );
};
