export const aiServicesTranslations = {
  headline: {
    en: "Every journey begins with a single step.",
    es: "Todo viaje comienza con un solo paso.",
    de: "Jede Reise beginnt mit einem einzigen Schritt.",
  },
  solutions: {
    headline: {
      en: "AI Business Strategy & Solutions",
      es: "Soluciones de Negocios y Estrategia de IA",
      de: "KI-Geschäftslösungen & Strategie",
    },
    description: {
      en: "Whether you're looking to revolutionize your operations or stay ahead of the competition, let us help you make the right AI decisions and find the perfect solutions for your business needs.",
      es: "Ya sea que busque revolucionar sus operaciones o mantenerse por delante de la competencia, permítanos ayudarle a tomar las decisiones correctas en IA y encontrar las soluciones perfectas para las necesidades de su negocio.",
      de: "Egal ob Sie Ihre Prozesse revolutionieren oder der Konkurrenz einen Schritt voraus sein möchten - wir unterstützen Sie bei den richtigen KI-Entscheidungen und finden die perfekten Lösungen für Ihre geschäftlichen Anforderungen.",
    },
    tag: {
      en: "",
    },
    a: {
      headline: {
        en: "AI Consulting & Strategy",
        es: "Consultoría de estrategia",
        de: "KI-Strategieberatung",
      },
      text: {
        en: "Curious about how AI can benefit you or your business? We're here to guide you, whether you're just starting or refining your approach. AI is evolving fast, and we'll help you navigate the options to find the best solutions for your goals.",
        es: "¿Tiene curiosidad sobre cómo la IA puede beneficiarle a usted o a su empresa? Estamos aquí para guiarle, ya sea que esté comenzando o perfeccionando su enfoque. La IA evoluciona rápidamente, y le ayudaremos a navegar las opciones para encontrar las mejores soluciones para sus objetivos.",
        de: "Interessiert, wie KI Ihnen oder Ihrem Unternehmen nutzen kann? Wir begleiten Sie - ob Sie gerade erst beginnen oder Ihren Ansatz verfeinern möchten. KI entwickelt sich schnell weiter, und wir helfen Ihnen dabei, die besten Lösungen für Ihre Ziele zu finden.",
      },
      listOfItems: {
        a: {
          text: {
            en: "Discover opportunities",
            es: "Encuentra oportunidades",
            de: "Entdecken Sie Chancen",
          },
        },
        b: {
          text: {
            en: "Identify high-impact areas",
            es: "Identifica áreas de alto impacto",
            de: "Identifizieren Sie Bereiche mit hohem Einfluss",
          },
        },
        c: {
          text: {
            en: "Craft strategic blueprints",
            es: "Diseña planes estratégicos",
            de: "Strategische Blaupausen entwerfen",
          },
        },
        d: {
          text: {
            en: "Validate winning use cases",
            es: "Validar Casos de Uso Ganadores",
            de: "Gewinnbringende Anwendungsfälle validieren",
          },
        },
      },
    },
    c: {
      headline: {
        en: "Global AI Network",
        es: "Red Global de IA",
        de: "Globales KI-Netzwerk",
      },
      text: {
        en: "Access a global network of AI professionals and innovators. Find exceptional talent, opportunities, and strategic partnerships.",
        es: "Acceda a una red global de profesionales e innovadores en IA. Encuentre talento excepcional, oportunidades y alianzas estratégicas.",
        de: "Greifen Sie auf ein globales Netzwerk von KI-Experten und Innovatoren zu. Finden Sie außergewöhnliche Talente, Chancen und strategische Partnerschaften.",
      },
      listOfItems: {
        a: {
          text: {
            en: "Founders & Visionaries",
            es: "Fundadores y Visionarios",
            de: "Gründer & Visionäre",
          },
        },
        b: {
          text: {
            en: "AI Experts",
            es: "Expertos en IA",
            de: "KI-Experten",
          },
        },
        c: {
          text: {
            en: "ML Engineers",
            es: "Ingenieros de ML",
            de: "ML-Ingenieure",
          },
        },
        d: {
          text: {
            en: "Data Specialists",
            es: "Especialistas en datos",
            de: "Daten-Spezialisten",
          },
        },
      },
    },
  },

  development: {
    headline: {
      en: "AI Development & Implementation",
      es: "Desarrollo e Implementación de IA",
      de: "KI-Entwicklung und -Implementierung",
    },
    a: {
      headline: {
        en: "AI Workflow Automation",
        es: "Automatización de Flujos de Trabajo con IA",
        de: "KI-Workflow-Automatisierung",
      },
      text: {
        en: "Transform your business operations by automating repetitive tasks and complex workflows with AI. Streamline processes, reduce manual work, and ensure consistency while dramatically improving operational efficiency, lowering costs, and reducing human error.",
        es: "Transforme sus operaciones empresariales automatizando tareas repetitivas y flujos de trabajo complejos con IA. Optimice procesos, reduzca el trabajo manual y asegure la consistencia mientras mejora drásticamente la eficiencia operativa, reduce costos y minimiza errores humanos.",
        de: "Transformieren Sie Ihre Geschäftsprozesse durch die Automatisierung repetitiver Aufgaben und komplexer Workflows mit KI. Optimieren Sie Prozesse, reduzieren Sie manuelle Arbeit und gewährleisten Sie Konsistenz bei gleichzeitiger deutlicher Verbesserung der betrieblichen Effizienz, Kostensenkung und Reduzierung menschlicher Fehler.",
      },
      listOfItems: {
        a: {
          text: {
            en: "Repetitive Task Automation",
            es: "Automatización de Tareas Repetitivas",
            de: "Automatisierung repetitiver Aufgaben",
          },
        },
        b: {
          text: {
            en: "Intelligent Process Automation",
            es: "Automatización Inteligente de Procesos",
            de: "Intelligente Prozessautomatisierung",
          },
        },
        c: {
          text: {
            en: "Cross-System Integrations",
            es: "Integraciones entre Sistemas",
            de: "Systemübergreifende Integrationen",
          },
        },
      },
    },
    b: {
      headline: {
        en: "Autonomous AI Agents",
        es: "Agentes Autónomos de IA",
        de: "Autonome KI-Agenten",
      },
      text: {
        en: "Deploy intelligent AI assistants that work independently across your business tools and platforms. These agents proactively handle tasks, make decisions within defined parameters, and coordinate across different systems - functioning like digital employees that work 24/7.",
        es: "Implemente asistentes inteligentes de IA que trabajen de forma independiente en sus herramientas y plataformas empresariales. Estos agentes gestionan tareas de forma proactiva, toman decisiones dentro de parámetros definidos y coordinan entre diferentes sistemas, funcionando como empleados digitales que trabajan 24/7.",
        de: "Setzen Sie intelligente KI-Assistenten ein, die eigenständig über Ihre Geschäftstools und Plattformen hinweg arbeiten. Diese Agenten bearbeiten proaktiv Aufgaben, treffen Entscheidungen innerhalb definierter Parameter und koordinieren über verschiedene Systeme hinweg - wie digitale Mitarbeiter, die rund um die Uhr arbeiten.",
      },
      listOfItems: {
        a: {
          text: {
            en: "Business Operations Agents",
            es: "Agentes de Operaciones Empresariales",
            de: "Geschäftsprozess-Agenten",
          },
        },
        b: {
          text: {
            en: "Intelligent Virtual Workforce",
            es: "Fuerza Laboral Virtual Inteligente",
            de: "Intelligente virtuelle Belegschaft",
          },
        },
        c: {
          text: {
            en: "Smart Decision Systems",
            es: "Sistemas Inteligentes de Decisión",
            de: "Intelligente Entscheidungssysteme",
          },
        },
      },
    },
    c: {
      headline: {
        en: "Knowledge Infrastructure Systems",
        es: "Sistemas de Infraestructura de Conocimiento",
        de: "Wissensinfrastruktursysteme",
      },
      text: {
        en: "Build a powerful foundation for your organization's collective intelligence by transforming how you store, access, and utilize information. Make your company's knowledge instantly accessible, searchable, and actionable across all departments.",
        es: "Construya una base sólida para la inteligencia colectiva de su organización transformando la forma de almacenar, acceder y utilizar la información. Haga que el conocimiento de su empresa sea instantáneamente accesible, localizable y ejecutable en todos los departamentos.",
        de: "Schaffen Sie eine leistungsstarke Grundlage für die kollektive Intelligenz Ihrer Organisation, indem Sie die Art und Weise transformieren, wie Sie Informationen speichern, abrufen und nutzen. Machen Sie das Wissen Ihres Unternehmens über alle Abteilungen hinweg sofort zugänglich, durchsuchbar und umsetzbar.",
      },
      listOfItems: {
        a: {
          text: {
            en: "Enterprise Memory System",
            es: "Sistema de Memoria Empresarial",
            de: "Unternehmensgedächtnissystem",
          },
        },
        b: {
          text: {
            en: "Vector Databases & Data Capture",
            es: "Bases de Datos Vectoriales y Captura de Datos",
            de: "Vektordatenbanken & Datenerfassung",
          },
        },
        c: {
          text: {
            en: "Smart Information Network",
            es: "Red Inteligente de Información",
            de: "Intelligentes Informationsnetzwerk",
          },
        },
      },
    },
  },
};
