import { motion } from "framer-motion";
import { ScanSearch } from "lucide-react";
import { cloneElement, isValidElement, ReactNode } from "react";
import { getThemeColor } from "../../tailwindUtils";

export type BubbleType = {
  text: string;
  icon?: any;
};

export const Bubble = ({ text, icon }: BubbleType) => {
  const defaultIconProps = {
    fill: getThemeColor("main"),
  };

  return (
    <motion.div
      key={text}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, delay: 0.3 }}
      viewport={{ once: true }}
      className={`body-1 flex relative flex-row items-center bg-secondary gap-2 py-2 px-5 rounded-lg tracking-wide w-fit font-medium`}
    >
      <span className="absolute bg-secondary -top-1/2  left-1/2 -translate-y-2 -translate-x-1/2 py-1 px-2 rounded-t-full">
        {icon &&
          isValidElement(icon) &&
          cloneElement(icon, { ...defaultIconProps })}
      </span>
      <p className="text-main">{text}</p>
    </motion.div>
  );
};
