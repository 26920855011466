import { useParams } from "react-router";
import { useTranslate } from "../../hooks/useTranslation";
import { Hero } from "../../modules/Hero/Hero";
import { StepForm } from "../../StepForm/StepForm";
import { PageWrapper } from "../PageWrapper";
import { formIdMap, FormIdMapResponseType } from "./formIdMap";
import { useEffect, useState } from "react";

export const FormPage = () => {
  const translate = useTranslate();
  const { id } = useParams();
  const [formDataFromId, setFormDataFromId] =
    useState<FormIdMapResponseType>(null);

  useEffect(() => {
    if (id) {
      const formData = formIdMap(id);
      setFormDataFromId(formData);
    }
  }, [id]);

  if (!formDataFromId) {
    return null;
  }

  return (
    <PageWrapper key={id}>
      {formDataFromId === null ? (
        <h1>Ups, something went wrong</h1>
      ) : (
        <div className="h-screen pt-10 pb-40">
          <StepForm
            key={id}
            className="h-full"
            formId={formDataFromId.formId}
            stepFormData={formDataFromId.data}
          />
        </div>
      )}
    </PageWrapper>
  );
};
