import { aiBusinessSectorsTranslations } from "./aiBusinessSectorsTranslations";
import { aiCompanyPositionsTranslations } from "./aiCompanyPositionsTranslations";
import { aiHowWorksTranslations } from "./aiHowWorksTranslations";
import { aiStartingTimeframesTranslations } from "./aiStartingTimeframesTranslations";
import { aiSubFieldsTranslations } from "./aiSubFieldsTranslations";
import { aiTalentTranslations } from "./aiTalentTranslations";
import { aiBookCallTranslations } from "./bookCallTranslations";

export const aiTranslations = {
  howWorks: aiHowWorksTranslations,
  businessSector: aiBusinessSectorsTranslations,
  talent: aiTalentTranslations,
  startingTimeframes: aiStartingTimeframesTranslations,
  subFields: aiSubFieldsTranslations,
  companyPositions: aiCompanyPositionsTranslations,
  ...aiBookCallTranslations,
};
