import { ArrowRight } from "lucide-react";
import { ArticleType } from "../../Articles/getArticles";
import { Card } from "../Card/Card";

import "./ArticleCard.scss";
import { useAtom } from "jotai";
import { translationLanguageAtom } from "../../jotai/state";

export const ArticleCard = ({
  article,
  className,
  showDescription = true,
}: {
  article: ArticleType;
  className?: string;
  showDescription?: boolean;
}) => {
  const [lang] = useAtom(translationLanguageAtom);

  return (
    <Card
      className={`shadow-sm shadow-accent relative !p-0 overflow-hidden group hover:shadow-md hover:shadow-accent transition-all duration-700 ${className}`}
      link={`/article/${article.metadata.id.toString()}`}
    >
      <div className="overflow-hidden  relative  rounded-lg">
        <img
          src={article.metadata.image}
          alt={article.title["en"]}
          className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-700"
        />
        <div className="rounded-full absolute top-5 right-5 bg-white text-secondary border border-secondary  px-4 py-2">
          {article.metadata.tag[lang]}
        </div>
      </div>
      <div className="p-5 flex flex-col gap-5 items-between">
        <h2 className="heading-3 text-gradient font-bold">
          {article.title["en"]}
        </h2>
        {showDescription && (
          <div>
            <div className="body-1">{article.metadata.description["en"]}</div>
          </div>
        )}
        <div className="body-2 flex mt-auto flex-row items-center justify-between">
          <p className="text-lightGrey text-sm">
            {article.metadata.postingDate}
          </p>
          <div className="translate-x-2 transition-all transition-300 group-hover:translate-x-3">
            <ArrowRight className="transition-all transition-500 group-hover:stroke-accent" />
          </div>
        </div>
      </div>
    </Card>
  );
};
