type FadeSeparatorPropsType = {
  className?: string;
};

export const FadeSeparator = ({ className }: FadeSeparatorPropsType) => {
  return (
    <hr
      className={`absolute bottom-0 w-full h-px border-0 opacity-20 ${
        className ? className : ""
      }`}
      style={{
        backgroundImage: `linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgb(var(--accent)) 52.07%, rgba(255, 255, 255, 0) 100%)`,
      }}
    />
  );
};
