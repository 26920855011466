import React from "react";
import { PageWrapper } from "../PageWrapper";
import { ArticleType, getArticles } from "../../Articles/getArticles";
import { ArticleCard } from "../../Components/ArticleCard/ArticleCard";
import { useTranslate } from "../../hooks/useTranslation";

// Define the props interface for ArticleCard
interface ArticleCardProps {
  article: ArticleType;
}

// Articles Page Component
export const ArticlesPage = () => {
  const articles: any = getArticles("ai");
  const translate = useTranslate();

  return (
    <PageWrapper>
      <div className="mx-auto w-full pb-5">
        <h1 className="heading-1 text-gradient my-20 lg:my-40 text-center">
          {translate("ai.galleryTitle")}
        </h1>
        <div className="flex flex-wrap gap-10 max-w-[1200px] justify-center w-full mx-auto">
          {articles.map((article: ArticleType, idx: any) => (
            <ArticleCard
              className="max-w-[460px]"
              key={idx}
              article={article}
            />
          ))}
        </div>
      </div>
    </PageWrapper>
  );
};
