import { motion } from "framer-motion";
import { ReactNode, useState } from "react";

type ButtonPropsType = {
  label: string;
  className?: string;
  disabled?: boolean;
  icon?: ReactNode;
  action: () => void;
};

export const Button = ({
  label,
  className,
  disabled,
  icon,
  action,
}: ButtonPropsType) => {
  const [flicker, setFlicker] = useState(false);

  const handleClick = () => {
    setFlicker(true);
    setTimeout(() => {
      action();
    }, 200);
    setTimeout(() => {
      setFlicker(false);
    }, 600);
  };

  return (
    <motion.button
      key={label}
      animate={{
        opacity: flicker ? [1, 0, 1, 0, 1] : 1,
      }}
      transition={{
        duration: 0.7,
        times: [0, 0.2, 0.5, 0.7, 1],
      }}
      disabled={disabled}
      className={`text-lg px-6 py-4 font-medium flex text-accent bg-accent/10  hover:bg-transparent transition-all duration-500 flex-row items-center justify-center gap-2 text-md shadow-smooth rounded-lg ${
        disabled ? "text-grey" : "border-accent-hover active:border-accent"
      } ${className}`}
      onClick={handleClick}
    >
      {icon}
      {label}
    </motion.button>
  );
};
