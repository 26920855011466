import { stepFormDataTalent } from "../../StepForm/stepFormData/ai/formData-ai-talent";
import { stepFormDataBookCall } from "../../StepForm/stepFormData/ai/formData-bookCall";
import { StepFormDataType } from "../../StepForm/StepFormTypes";

export type FormIdMapResponseType = {
  formId: string;
  data: StepFormDataType;
} | null;

export const formIdMap = (id: string): FormIdMapResponseType => {
  switch (id) {
    case "discovery":
      return {
        formId: "ai-book-call",
        data: stepFormDataBookCall,
      };
    case "talent":
      return {
        formId: "ai-cta-talent",
        data: stepFormDataTalent,
      };
    default:
      return null;
  }
};
