import { motion } from "framer-motion";
import { ReactNode } from "react";

export type SectionWrapperType = {
  fullWidth?: boolean;
  headline?: string;
  tagline?: string;
  description?: string;
  children?: ReactNode;
  id?: string;
  dark?: boolean;
  transition?: boolean;
};

export const SectionWrapper = ({
  children,
  fullWidth,
  headline,
  description,
  tagline,
  dark,
  id,
  transition,
}: SectionWrapperType) => {
  const moduleClassName = "min-h-screen flex items-center py-20";
  const moduleWidthClassName = "max-w-[1400px] mx-auto";

  const wrapperClassName = `${moduleClassName} ${
    fullWidth ? "full-width-section" : moduleWidthClassName
  } ${dark ? "bg-main" : ""} `;

  const content = () => {
    return (
      <div className="w-full ">
        <div className=" mb-10 lg:mb-20 px-5 flex flex-col items-center">
          {headline && <h2 className={`heading-2 text-center ${dark ? "text-white" : ""} `}>{headline}</h2>}
          {description && (
            <p className="text-center body-1 text-lightGrey mt-5 max-w-[800px]">
              {description}
            </p>
          )}
        </div>
        {children}
      </div>
    );
  };

  const wrapper = (children: ReactNode) => {
    if (transition) {
      return (
        <motion.div
          initial={{ opacity: 0, y: 60 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: dark ? 0 : 1, delay: dark ? 0 : 0.3 }}
          viewport={{ once: dark ? false : true }}
          className={wrapperClassName}
          id={id}
        >
          {children}
        </motion.div>
      );
    } else {
      return (
        <div id={id} className={wrapperClassName}>
          {children}
        </div>
      );
    }
  };

  return wrapper(content());
};
