export default function CarouselItem({
  text,
  image,
}: {
  text: string;
  image: any;
}) {
  return (
    <div className="relative h-fit">
      <p className="text-40 md:text-2xl px-4 text-gradient font-bold tracking-[0.4rem] z-10 backdrop-blur-sm text-gradient absolute top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2">{text}</p>
      <div className="opacity-5">{image}</div>
    </div>
  );
}
