import { Card } from "../../Components/Card/Card";
import { useScroll } from "../../Providers/ScrollContextProvider";
import { SafeTextRenderer } from "../../Utils/SafeTextRenderer";

interface TextQuoteProps {
  bgImage: string;
  text: string;
  quote: string;
  author: string;
  ref?: any;
}

export const TextQuote = ({
  bgImage,
  text,
  quote,
  author,
  ref,
}: TextQuoteProps) => {
  const { sectionRefs } = useScroll();

  return (
    <Card className="p-5 lg:p-10 grid grid-cols-3 lg:grid-cols-6 gap-10 lg:gap-20 overflow-hidden shadow-sm shadow-accent">
      <div ref={sectionRefs.ourMission} className="col-span-3">
        <SafeTextRenderer className="body-1 z-10 relative" text={text} />
      </div>
      <div className="col-span-3 relative w-full flex">
        <img
          src={bgImage}
          alt="Philosophy"
          className="absolute min-w-[500px] h-[500px] -right-20 -top-80 lg:-top-10 opacity-10"
        />
        <div className="flex flex-col z-10 ">
          <p className="heading-2 text-gradient font-bold italic">”{quote}”</p>
          <p className="heading-3 text-gradient ml-auto mt-auto">- {author}</p>
        </div>
      </div>
    </Card>
  );
};
