import { useNavigate } from "react-router";
import { Bubbles } from "../../Components/Bubbles/Bubbles";
import { Button } from "../../Components/Button/Button";
import { BubbleType } from "../../Components/TextBubble/TextBubble";

type BubblesSectionPropsType = {
  bubbles: BubbleType[];
  button?: {
    label: string;
    link: string;
  };
};

export const BubblesSection = ({
  bubbles,
  button,
}: BubblesSectionPropsType) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center gap-20">
      <Bubbles bubbles={bubbles} />
      {button && (
        <Button
          highlight
          label={button.label}
          action={() => navigate(button.link)}
        />
      )}
    </div>
  );
};
