import { aiTranslations } from "./aiPage/aiTranslations";

export const translationsStrings = {
  articles: {
    en: "Articles",
    de: "Artikel",
    es: "Artículos"
  },
  ai: aiTranslations,
};
