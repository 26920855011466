import { Bubble, BubbleType } from "../TextBubble/TextBubble";

export type BubblesPropsType = {
  bubbles: BubbleType[];
};

export const Bubbles = ({ bubbles }: BubblesPropsType) => {
  return (
    <div className="flex flex-wrap w-full justify-center items-center gap-x-10 gap-y-10">
      {bubbles.map((bubble) => {
        return <Bubble {...bubble} />;
      })}
    </div>
  );
};
