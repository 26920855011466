export const InfoText = ({
  text,
  className,
}: {
  text: string;
  className: string;
}) => {
  return (
    <p
      className={`body-1 drop-shadow-md px-5 md:px-20 2xl:px-40 text-gradient text-center ${
        className ? className : ""
      } `}
    >
      {text}
    </p>
  );
};
