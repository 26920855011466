import { useEffect, useState } from "react";

import { Button } from "./Components/Button";
import { InputField } from "./Components/InputField";

import {
  StepFormAnswerType,
  StepFormButtonType,
  StepFormInputFieldType,
} from "./StepFormTypes";
import { useStepFormTranslations } from "./StepFormTranslations/useStepFormTranslations";
import { motion } from "framer-motion";

export type StepFormButtonActionType = {
  userActionType: "button_options";
  actionData: StepFormButtonType;
};

export type StepFormInputFieldAction = {
  userActionType: "input_fields";
  actionData: {
    field: StepFormInputFieldType;
    value: string;
  };
};

export type StepFormInputFieldButtonAction = {
  userActionType: "input_fields_button";
  nextStepId: string;
  submitForm?: boolean;
};

export type FormUserActionType =
  | StepFormButtonActionType
  | StepFormInputFieldAction
  | StepFormInputFieldButtonAction;

export const useRenderStepAnswer = (
  answer: StepFormAnswerType,
  answerInState: any,
  onFormAction: (action: FormUserActionType) => void,
  translate: string
) => {
  const translateString = useStepFormTranslations(translate);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (answer.type === "input_fields") {
      const allFieldsValid = answer.answerConfig.fields.every((field) => {
        if (field.validationId) {
          const value =
            answerInState?.user_answer?.[field.id]?.inputValue || "";
          if (field.validationId === "email") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(value);
          } else if (field.validationId === "name") {
            return value.length >= 3;
          }
        }
        return true;
      });
      setIsFormValid(allFieldsValid);
    }
  }, [answerInState, answer.answerConfig]);

  switch (answer.type) {
    case "button_options":
      return (
        <motion.div
          key="button-options"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0 }}
          exit={{ opacity: 0 }}
          className="flex justify-center gap-5 flex-wrap"
        >
          {answer.answerConfig.options.map((option) => (
            <Button
              key={option.id}
              label={translateString(option.label)}
              action={() =>
                onFormAction({
                  userActionType: "button_options",
                  actionData: option,
                })
              }
            />
          ))}
        </motion.div>
      );
    case "input_fields":
      return (
        <div className="flex flex-col justify-center gap-5 w-full">
          {answer.answerConfig.fields.map((field) => (
            <InputField
              label={translateString(field.label)}
              key={field.id}
              validationId={field.validationId}
              value={answerInState?.user_answer?.[field.id]?.inputValue}
              onChange={(value) =>
                onFormAction({
                  userActionType: "input_fields",
                  actionData: { field: field, value: value },
                })
              }
            />
          ))}
          <Button
            label={translateString(answer.answerConfig.nextButtonLabel)}
            action={() =>
              onFormAction({
                userActionType: "input_fields_button",
                nextStepId: answer.answerConfig.nextStepId,
                submitForm: answer.answerConfig.submitForm,
              })
            }
            disabled={!isFormValid}
          />
        </div>
      );
    default:
      break;
  }
};
