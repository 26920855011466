import { ReactNode } from "react";
import { Link } from "react-router-dom";

type CardPropsType = {
  children: ReactNode;
  className?: string;
  link?: string;
};

export const Card = ({ children, className, link }: CardPropsType) => {
  const mainClassname = `${link ? "shadow-accent" : "shadow-secondary"} p-5 shadow-sm bg-background rounded-bl-2xl rounded-t-2xl ${className}`;
  return link ? (
    <Link className={mainClassname} to={link}>
      {children}
    </Link>
  ) : (
    <div className={mainClassname}>{children}</div>
  );
};
