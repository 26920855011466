export const aiTalentTranslations = {
  start: {
    en: "Welcome to Our AI Talent Form: Apply as an Agency or Individual",
    es: "Bienvenido a Nuestro Formulario de Talento en IA: Aplica como Agencia o Individuo",
    de: "Willkommen bei unserem AI-Talentformular: Bewerben Sie sich als Agentur oder Einzelperson",
  },

  asExpertLabel: {
    en: "Apply as professional",
    es: "Postular como profesional",
    de: "Als Profi bewerben",
  },
  asAgencyLabel: {
    en: "We are a team",
    es: "Somos un equipo",
    de: "Wir sind ein Team",
  },
  experience: {
    title: {
      en: "How much experience do you have in the AI field ?",
      es: "¿Cuánta experiencia tienes en el campo de la IA?",
      de: "Wie viel Erfahrung haben Sie im Bereich KI?",
    },
    noExperienceLabel: {
      en: "No experience",
      es: "Sin experiencia",
      de: "Keine Erfahrung",
    },
    underOne: {
      en: "Under 1 year",
      es: "Menos de 1 año",
      de: "Unter 1 Jahr",
    },
    oneThree: {
      en: "1-3 Years",
      es: "1-3 años",
      de: "1-3 Jahre",
    },
    threeFive: {
      en: "3-5 Years",
      es: "3-5 años",
      de: "3-5 Jahre",
    },
    overFive: {
      en: "Over 5 years",
      es: "Más de 5 años",
      de: "Über 5 Jahre",
    },
  },
  teamSize: {
    title: {
      en: "How big is your team ?",
      es: "¿Qué tan grande es tu equipo?",
      de: "Wie groß ist Ihr Team?",
    },
    a: {
      en: "2-5",
      es: "2-5",
      de: "2-5",
    },
    b: {
      en: "5-10",
      es: "5-10",
      de: "5-10",
    },
    c: {
      en: "10-15",
      es: "10-15",
      de: "10-15",
    },
    d: {
      en: "Over 15",
      es: "Más de 15",
      de: "Über 15",
    },
  },
  fields: {
    title: {
      en: "What are you mostly focused on",
      es: "¿En qué te enfocas principalmente?",
      de: "Worauf konzentrieren Sie sich hauptsächlich?",
    },
    other: {
      en: "Other",
      es: "Otro",
      de: "Andere",
    },
    multiple: {
      en: "Multiple",
      es: "Múltiple",
      de: "Mehrere",
    },
  },
  availability: {
    title: {
      en: "When would you be available to pick up projects ?",
      es: "¿Cuándo estaría disponible para recoger proyectos?",
      de: "Wann wären Sie verfügbar, um Projekte anzunehmen?",
    },
  },
  contactInfo: {
    title: {
      en: "How can we find out more about you and get in touch ?",
      es: "¿Cómo podemos obtener más información sobre ti y ponernos en contacto?",
      de: "Wie können wir mehr über Sie erfahren und Kontakt aufnehmen?",
    },
    name: {
      en: "Name",
      es: "Nombre",
      de: "Name",
    },
    websiteLinkedin: {
      en: "Website / Linkedin",
      es: "Pagina Web / Linkedin",
      de: "Website / Linkedin",
    },
    email: {
      en: "Email",
      es: "Email",
      de: "Email",
    },
    buttonLabel: {
      en: "Next",
      es: "Siguiente",
      de: "Weiter",
    },
  },
};
