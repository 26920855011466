import { ArticleType } from "../getArticles";

export const article_2: ArticleType = {
  title: {
    en: "24/7 AI Voice Assistant for Enhanced Customer Support",
    de: "24/7 KI-Sprachassistent für verbesserten Kundenservice",
    es: "Asistente de Voz IA 24/7 para Mejorar el Soporte al Cliente",
  },
  metadata: {
    id: 2,
    postingDate: "28.9.2024",
    image: require("./images/2.png"),
    tag: { en: "AI Case Study", de: "AI-Fallstudie", es: "Caso de estudio IA" },
    description: {
      en: "The story of how an AI voice assistant transformed customer service for an e-commerce giant, cutting costs and response times.",
      es: "La historia de cómo un asistente de voz de IA transformó el servicio al cliente para un gigante del e-commerce, reduciendo costos y tiempos de respuesta.",
      de: "Die Geschichte, wie ein KI-basierter Sprachassistent den Kundenservice für einen E-Commerce-Riesen transformierte, Kosten und Reaktionszeiten senkte.",
    },
    author: "Millerio",
  },
  sections: [
    {
      heading: {
        en: "Executive Summary",
        de: "Zusammenfassung",
        es: "Resumen Ejecutivo",
      },
      content: [
        {
          en: "An e-commerce company faced high customer service costs and long wait times.",
          de: "Ein E-Commerce-Unternehmen hatte hohe Kundendienstkosten und lange Wartezeiten.",
          es: "Una empresa de comercio electrónico enfrentaba altos costos de servicio al cliente y largos tiempos de espera.",
        },
        {
          en: "We deployed a 24/7 AI voice assistant to handle customer queries.",
          de: "Wir setzten einen 24/7 KI-Sprachassistenten ein, um Kundenanfragen zu bearbeiten.",
          es: "Implementamos un asistente de voz IA 24/7 para manejar las consultas de los clientes.",
        },
        {
          en: "Results: 30% reduction in support costs, 44% decrease in average response time, 20% improvement in customer satisfaction scores.",
          de: "Ergebnisse: 30% Reduzierung der Supportkosten, 44% kürzere Reaktionszeiten, 20% Verbesserung der Kundenzufriedenheit.",
          es: "Resultados: 30% de reducción en los costos de soporte, 44% de disminución en el tiempo promedio de respuesta, 20% de mejora en las puntuaciones de satisfacción del cliente.",
        },
      ],
    },
    {
      heading: {
        en: "Our AI Solution",
        de: "Unsere KI-Lösung",
        es: "Nuestra Solución de IA",
      },
      content: [
        {
          en: "Advanced speech recognition and natural language understanding.",
          de: "Erweiterte Spracherkennung und Sprachverständnis.",
          es: "Reconocimiento de voz avanzado y comprensión del lenguaje natural.",
        },
        {
          en: "Dynamic response generation based on customer intent and historical data.",
          de: "Dynamische Antwortgenerierung basierend auf Kundenabsichten und historischen Daten.",
          es: "Generación dinámica de respuestas basada en la intención del cliente y los datos históricos.",
        },
        {
          en: "Seamless handover to human agents for complex issues.",
          de: "Nahtlose Übergabe an menschliche Agenten für komplexe Probleme.",
          es: "Transferencia fluida a agentes humanos para problemas complejos.",
        },
      ],
    },
    {
      heading: {
        en: "Results and Impact",
        de: "Ergebnisse und Auswirkungen",
        es: "Resultados e Impacto",
      },
      content: {
        quantitative: [
          {
            en: "30% cost reduction",
            de: "30% Kostenreduzierung",
            es: "30% de reducción de costos",
          },
          {
            en: "44% faster response times",
            de: "44% schnellere Reaktionszeiten",
            es: "44% de tiempos de respuesta más rápidos",
          },
          {
            en: "20% higher CSAT scores",
            de: "20% höhere Kundenzufriedenheitswerte",
            es: "20% de aumento en las puntuaciones de satisfacción del cliente (CSAT)",
          },
        ],
        qualitative: [
          {
            en: "Improved customer experience",
            de: "Verbesserte Kundenerfahrung",
            es: "Mejora en la experiencia del cliente",
          },
          {
            en: "Reduced workload on human agents",
            de: "Verminderte Arbeitslast für menschliche Agenten",
            es: "Reducción de la carga de trabajo en los agentes humanos",
          },
          {
            en: "Consistent service quality",
            de: "Konstante Servicequalität",
            es: "Calidad de servicio consistente",
          },
        ],
      },
    },
    {
      heading: {
        en: "Future Opportunities",
        de: "Zukünftige Chancen",
        es: "Oportunidades Futuras",
      },
      content: [
        {
          en: "Integrate sentiment analysis to detect and prioritize urgent customer issues.",
          de: "Sentimentanalyse integrieren, um dringende Kundenanliegen zu erkennen und zu priorisieren.",
          es: "Integrar análisis de sentimientos para detectar y priorizar problemas urgentes de los clientes.",
        },
        {
          en: "Expand the system to handle outbound calls for proactive customer engagement.",
          de: "Das System erweitern, um ausgehende Anrufe für proaktive Kundeninteraktionen zu übernehmen.",
          es: "Expandir el sistema para manejar llamadas salientes para una interacción proactiva con los clientes.",
        },
      ],
    },
  ],
};
