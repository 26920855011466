import { aiArticles } from "./ai/aiArticles";

interface LanguageObject {
  [key: string]: string;
}

interface Heading extends LanguageObject {}

interface ContentItem extends LanguageObject {}

interface QuantitativeResult extends LanguageObject {}

interface QualitativeResult extends LanguageObject {}

interface Metadata {
  id: number;
  postingDate: string;
  image: string;
  description: LanguageObject;
  tag: LanguageObject;
  author: string;
}

type ResultsContent = {
  [key: string]: (QuantitativeResult | QualitativeResult)[];
};

interface Section {
  heading: Heading;
  content: ContentItem[] | ResultsContent;
}

export interface ArticleType {
  title: LanguageObject;
  metadata: Metadata;
  sections: Section[];
}

export const getArticles = (category: string): ArticleType[] => {
  switch (category) {
    case "ai":
      return aiArticles;
    default:
      return aiArticles;
  }
};
