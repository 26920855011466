import { ButtonActionsType, StepFormValidationIdsType } from "../StepFormTypes";

const getIdFromLabel = (label: string) => {
  return label
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/\//g, "-")
    .replace(/[^a-z0-9\-]/g, "");
};

///////////////// BUTTONS

export const getStepFormButton = (
  label: string,
  actionData: string,
  actionType?: ButtonActionsType,
  highlight?: boolean
) => {
  let actionTypeToUse: ButtonActionsType = "changeStep";
  if (actionType) {
    actionTypeToUse = actionType;
  }

  let buttonBase: any = {
    id: getIdFromLabel(label),
    label: label,
    actionType: actionTypeToUse,
    highlight: highlight,
  };

  switch (actionTypeToUse) {
    case "changeStep":
      buttonBase.nextStepId = actionData;
      break;
    default:
      buttonBase.nextStepId = actionData;
      break;
  }

  return buttonBase;
};

export const getStepFormButtonFromArray = (
  array: string[],
  actionData: string,
  actionType?: ButtonActionsType
) => {
  return array.map((industry) =>
    getStepFormButton(industry, actionData, actionType)
  );
};

/////////////// INPUT FIELDS

export const getStepFormInputField = (
  label: string,
  validationId?: StepFormValidationIdsType
) => {
  return {
    id: getIdFromLabel(label),
    label: label,
    validationId,
  };
};

// export const getStepFormInputFieldsFromArray = (
//   array: string[],
//   actionData: string,
//   actionType?: ButtonActionsType
// ) => {
//   return array.map((industry) =>
//     getStepFormInputField(industry, actionData)
//   );
// };
