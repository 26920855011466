import { ArticleType } from "../getArticles";
import { article_1 } from "./1";
import { article_2 } from "./2";
import { article_3 } from "./3";
import { article_4 } from "./4";
import { article_5 } from "./5";
import { article_6 } from "./6";
import { article_7 } from "./7";

export const aiArticles: ArticleType[] = [
  article_1,
  article_2,
  article_3,
  article_4,
  article_5,
  article_6,
  article_7,
];
