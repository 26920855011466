import { ArticleType } from "../getArticles";

export const article_4: ArticleType = {
  title: {
    en: "AI Resume Analysis and Candidate Classification",
    es: "Análisis de currículum impulsado por IA y clasificación de candidatos para reclutamiento",
    de: "KI-gestützte Lebenslauf-Analyse und Kandidatenklassifizierung für die Rekrutierung",
  },
  metadata: {
    id: 4,
    postingDate: "13.8.2024",
    image: require("./images/4.png"),
    description: {
      en: "Streamlining recruitment with AI: A case study in efficiency and candidate quality improvement for a large corporation.",
      es: "Optimización del reclutamiento con IA: Un estudio de caso en eficiencia y mejora de la calidad de los candidatos para una gran corporación.",
      de: "Rationalisierung der Rekrutierung mit KI: Eine Fallstudie zur Effizienz und Verbesserung der Kandidatenqualität für ein großes Unternehmen.",
    },
    tag: { en: "AI Case Study", de: "AI-Fallstudie", es: "Caso de estudio IA" },
    author: "Millerio",
  },
  sections: [
    {
      heading: {
        en: "Executive Summary",
        es: "Resumen Ejecutivo",
        de: "Zusammenfassung",
      },
      content: [
        {
          en: "A large corporation faced inefficiencies in screening thousands of job applications.",
          es: "Una gran corporación enfrentaba ineficiencias al filtrar miles de solicitudes de empleo.",
          de: "Ein großes Unternehmen sah sich mit Ineffizienzen beim Prüfen von Tausenden von Bewerbungen konfrontiert.",
        },
        {
          en: "We developed an AI-powered resume analysis and candidate classification system.",
          es: "Desarrollamos un sistema de análisis de currículum y clasificación de candidatos impulsado por IA.",
          de: "Wir entwickelten ein KI-gestütztes System zur Analyse von Lebensläufen und zur Klassifizierung von Kandidaten.",
        },
        {
          en: "Results: +70% reduction in initial screening time, 40% improvement in quality of shortlisted candidates, 25% increase in successful hires.",
          es: "Resultados: Reducción del 70% en el tiempo de preselección inicial, mejora del 40% en la calidad de los candidatos preseleccionados, aumento del 25% en contrataciones exitosas.",
          de: "Ergebnisse: +70% Reduktion der Zeit für die Vorauswahl, 40% Verbesserung der Qualität der vorgewählten Kandidaten, 25% Zunahme der erfolgreichen Einstellungen.",
        },
      ],
    },
    {
      heading: {
        en: "Our AI Solution",
        es: "Nuestra Solución de IA",
        de: "Unsere KI-Lösung",
      },
      content: [
        {
          en: "Natural Language Processing to extract and categorize information from resumes.",
          es: "Procesamiento del Lenguaje Natural para extraer y categorizar información de los currículum.",
          de: "Verarbeitung natürlicher Sprache zur Extraktion und Kategorisierung von Informationen aus Lebensläufen.",
        },
        {
          en: "Machine Learning algorithms to match candidates with job requirements.",
          es: "Algoritmos de Aprendizaje Automático para emparejar candidatos con los requisitos del trabajo.",
          de: "Maschinelles Lernen-Algorithmen zur Übereinstimmung von Kandidaten mit den Anforderungen der Stelle.",
        },
        {
          en: "Bias detection and mitigation to ensure fair candidate evaluation.",
          es: "Detección y mitigación de sesgos para asegurar una evaluación justa de los candidatos.",
          de: "Erkennung und Milderung von Vorurteilen, um eine faire Bewertung der Kandidaten sicherzustellen.",
        },
      ],
    },
    {
      heading: {
        en: "Results and Impact",
        es: "Resultados e Impacto",
        de: "Ergebnisse und Auswirkungen",
      },
      content: {
        quantitative: [
          {
            en: "+70% time saved in screening",
            es: "70% de tiempo ahorrado en la preselección",
            de: "70% Zeitersparnis bei der Vorauswahl",
          },
          {
            en: "40% better shortlist quality",
            es: "40% mejor calidad en la lista corta",
            de: "40% bessere Qualität der Auswahl",
          },
          {
            en: "25% more successful hires",
            es: "25% más de contrataciones exitosas",
            de: "25% mehr erfolgreiche Einstellungen",
          },
        ],
        qualitative: [
          {
            en: "More diverse candidate pool",
            es: "Máxima diversidad en la base de candidatos",
            de: "Vielfältigere Kandidatenbasis",
          },
          {
            en: "Reduced unconscious bias",
            es: "Reducción del sesgo inconsciente",
            de: "Reduzierung unbewusster Vorurteile",
          },
          {
            en: "Improved candidate experience",
            es: "Mejorada experiencia del candidato",
            de: "Verbesserte Kandidaten-Erfahrung",
          },
        ],
      },
    },
    {
      heading: {
        en: "Future Opportunities",
        es: "Oportunidades Futuras",
        de: "Zukünftige Möglichkeiten",
      },
      content: [
        {
          en: "Integrate with AI-driven interview scheduling and preliminary candidate assessment tools.",
          es: "Integrar con herramientas de programación de entrevistas impulsadas por IA y evaluación preliminar de candidatos.",
          de: "Integration mit KI-gestützten Tools für die Terminierung von Vorstellungsgesprächen und vorläufige Bewertung von Kandidaten.",
        },
        {
          en: "Expand system to include predictive analytics for candidate success and retention.",
          es: "Expandir el sistema para incluir análisis predictivos del éxito y retención de candidatos.",
          de: "Erweitern des Systems um prädiktive Analysen für den Erfolg und die Bindung von Kandidaten.",
        },
      ],
    },
  ],
};
