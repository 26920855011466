import { useForm } from "@formspree/react";
import { useCallback } from "react";
import { useStepFormTranslations } from "../StepFormTranslations/useStepFormTranslations";
import { FormStateType } from "../FormState/FormState";

export const useFormspreeSubmission = (formId: string) => {
  const [state, handleSubmit] = useForm(formId);

  const submitForm = useCallback(
    (formState: FormStateType) => {
      console.log("submit form in hook");
      const flattenedState = {
        formId: formState.formId,
        ...Object.entries(formState.userActions).reduce(
          (acc: any, [key, value]) => {
            const title = value.step_title;
            if (typeof value.user_answer === "string") {
              acc[title] = value.user_answer;
            } else if (typeof value.user_answer === "object") {
              Object.entries(value.user_answer).forEach(
                ([subKey, subValue]) => {
                  acc[`${title}_${subKey}`] = subValue.inputValue;
                }
              );
            }
            return acc;
          },
          {}
        ),
      };
      console.log("flattened State: ", flattenedState);
      handleSubmit(flattenedState);
    },
    [handleSubmit]
  );
  return {
    submitForm,
    isSubmitting: state.submitting,
    isSubmitted: state.succeeded,
    errors: state.errors,
  };
};
