import { useNavigate } from "react-router";
import { Accordion } from "../../Components/Accordion/Accordion";
import { Button } from "../../Components/Button/Button";
import { useTranslate } from "../../hooks/useTranslation";
import { useScroll } from "../../Providers/ScrollContextProvider";
import { StepForm } from "../../StepForm/StepForm";
import { aiFaqData, faqDataType } from "./faqData";
import { InfoText } from "../../Components/InfoText/InfoText";

type FAQPropsType = {
  className?: string;
  data: faqDataType;
};

export const FAQ = ({ className, data }: FAQPropsType) => {
  const translate = useTranslate();
  const { sectionRefs } = useScroll();
  const navigate = useNavigate();
  return (
    <div ref={sectionRefs.qaSection} className={`${className}`}>
      <div className="flex flex-col">
        <Accordion className="max-w-[800px] mx-auto" items={data.items} />
        <InfoText className="mt-40 mb-10" text={translate("ai.questionCTAButton")} />
        <Button
          label={translate("ai.discoveryCallText")}
          highlight
          className="w-fit mx-auto"
          action={() => navigate("/form/discovery")}
        />
      </div>
    </div>
  );
};
