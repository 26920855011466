export const themeColors = {
  transparent: "transparent",
  current: "currentColor",
  main: "rgb(var(--main))",
  background: "rgb(var(--background))",
  accent: "rgb(var(--accent))",
  secondary: "rgb(var(--secondary))",
  grey: "rgb(var(--grey))",
  darkGrey: "rgb(var(--darkGrey))",
  lightGrey: "rgb(var(--lightGrey))",
  dark: "rgb(var(--dark))",
  stroke: "rgb(var(--stroke))",
  blue: "rgb(var(--blue))",
  red: "rgb(var(--red))",
  white: "rgb(var(--white))",
};
